
/*fullscr nav*/

// .fs-menu-data {
//     opacity: 0;
//     visibility: hidden;
//     -webkit-transition: opacity 0.5s;
//             transition: opacity 0.5s;
//     visibility: 0s 0.5s;
//     transition: opacity 0.5s, visibility 0s 0.5s;
//     -webkit-animation-duration: 0.8s;
//        -moz-animation-duration: 0.8s;
//          -o-animation-duration: 0.8s;
//             animation-duration: 0.8s;
//     -webkit-animation-delay: 0.5s;
//        -moz-animation-delay: 0.5s;
//          -o-animation-delay: 0.5s;
//             animation-delay: 0.5s;
// }
//
// .fs-menu {
//     position: fixed;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     display: block;
//     color: $white;
//     background: rgba($primary-color,0.96);
//     z-index: 13;
// }
// .fs-menu a {
//     color: $white;
//     font-family: $header-font-family;
// }
//
// .fs-menu-open {
//     opacity: 1;
//     visibility: visible;
//     -webkit-transition: opacity 0.5s;
//             transition: opacity 0.5s;
// }
//
// .fs-menu-close {
//     position: absolute;
//     right:1rem;
//     top:1rem;
// }
//
// //tmp menu stlyes from codepen
// // http://codepen.io/PaulVanO/pen/GgGeyE
// .inner-content-fsm {
//
//     position: relative;
//     height: 70%;
//     top: 50%;
//     transform: translateY(-50%);
//     font-size: 40px;
//     //font-family: 'Vollkorn', serif;
//     font-weight: 400;
//     text-align: center;
//     z-index: 100;
// }





.off-canvas {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  .menu {
    width: 100%;
  }
  section {
    padding: 0.7rem 1rem;
    &.link-studio {
      align-self: flex-end;
      // position: absolute;
      // bottom: 0;
      width: 100%;
      background-color: $light-gray;

      transition: all 0.2s ease-out;
      h6 {
        color: $black;
        margin: 0;
      }
      &:hover {
        background-color: $black;
        & h6 {
          color: $white;
        }
      }
    }
  }
}
