// each projects list sass

$list: (
  //2024
  ilinu-ilidi,

  //2023
  afrontaciones,
  encarnadas,

  //2022
  medusal,
  rayoverde,

  //2020-21
  ceudabocaronin,
  invocaciones,

  //2019
  memorias,
  prole,

  //2018
  mother,

  // 2017
  leviathan,
  calles,
  lucid,

  //2016
  ggigirlah,
  laid-by-his-side,
  lesttk,
  solar-flare,
  altamisa,
  flight-of-the-monarchs,
  el-tren,
  rgtrrn-andamio,
  tuyuc,
  el-lenguaje-de-los-arboles,
  serie-de-conciertos,

  //2015
  el-hacedor,
  pirata-de-patio-adentro,
  los-dias-terrestres2,
  mikrokosmika,

  //2014
  geografias-sonoras,
  los-dias-terrestres,

  //2013
  variaciones-ninchi,
  vortices-desplazados,
  accion-transdisciplinaria,
  casas-documental,
  livecoding-visual-sonoro,
  buttons,

  //2012
  horizontalidad-silvia-plath,

);

 @mixin project-images {
  @each $project in $list {
      @for $i from 1 through 5 {
        .#{$project} {
          .owl-item:nth-child(n+#{$i}) {
            figure {
              background-image: url("../img/projects/#{$project}/0#{$i}.jpg");
            }
          }
        }
      }

  }
  @each $project in $list {
    @for $i from 1 through 5 {
      .#{$project} {
        button.owl-thumb-item:nth-child(n+#{$i}) {
            background-image: url("../img/projects/#{$project}/0#{$i}.jpg");
        }
      }
    }
  }
}

@include project-images;
