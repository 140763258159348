

$grid-figcaption-color: #3c4a50;

// production section

/*-------                               --------*/
/****                  HOVER:                ****/
/* https://github.com/codrops/HoverEffectIdeas -*/
/*-------                               --------*/
.grid {
    position: relative;
    // margin: 0 auto;
    padding: 1em 0 4em;
    // max-width: 1000px;
//    list-style: none;
    text-align: center;
}

.grid .folium {
    @include breakpoint(small only) {
        margin-bottom: 2.5rem;
    }
}

/* Common style */
.grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 0;
 //   min-width: 320px;
 //   max-width: 480px;
 //   max-height: 360px;
    width: 100%;
    background: $secondary-color;
    text-align: center;
    cursor: pointer;
}

.grid figure img {
 //   position: relative;
 //   display: block;
 //   min-height: 100%;
 //   max-width: 100%;
    opacity: 0.9;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

.grid figure:hover > img {
 //   position: relative;
 //   display: block;
 //   min-height: 100%;
 //   max-width: 100%;
    opacity: 0.4;
}

.grid figure figcaption {
    padding: 2em;
    color: $white;
    text-transform: uppercase;
    font-size: 1.25em;
    //-webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Anchor will cover the whole item by default */
.grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure h4 {
    word-spacing: -0.05em;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    & span {
        font-weight: 800;
    }
}

.grid figure h6 {
    word-spacing: -0.05em;
    font-weight: 300;
    line-height: 1.2;
}

.grid figure p {
    margin: 0;
    letter-spacing: 1px;
    font-size: 68.5%;
}

/*---------------*/
/***** Zoe *****/
/*---------------*/

figure.effect-zoe figcaption {
    top: auto;
    bottom: 0;
    padding: 1em;
    height: auto;
    background: $white;
    color: $grid-figcaption-color;
    //-webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    //-webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    @include breakpoint(medium) {
      //-webkit-transform: translate3d(0,100%,0);
      transform: translate3d(0,100%,0);
    }
}

figure.effect-zoe h2 {
    float: left;
}

figure.effect-zoe p.icon-links a {
    float: right;
    color: #3c4a50;
    font-size: 1.4em;
}

figure.effect-zoe:hover p.icon-links a:hover,
figure.effect-zoe:hover p.icon-links a:focus {
    // color: scale($grid-figcaption-color, darken, 15%);
    color: $primary-color;
}

figure.effect-zoe p.description {
    position: absolute;
    bottom: 6em;
    padding: 0.5em;
    color: #fefefe;
    text-transform: none;
    font-size: 90%;
    //-webkit-backface-visibility: hidden; /* Fix for Chrome 37.0.2062.120 (Mac) */
    //-webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
    //-webkit-backface-visibility: hidden; /* Fix for Chrome 37.0.2062.120 (Mac) */
    opacity: 1;
    @include breakpoint(medium only) {
        opacity: 0;
        padding:1em 0.3em;
        bottom: 4em;
    }
    @include  breakpoint(large) {
        opacity: 0;
        padding:2em 0.3em;
        bottom: 5em;
    }
}

figure.effect-zoe h2,
figure.effect-zoe p.icon-links a {
    //-webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    //-webkit-transform: translate3d(0,200%,0);
    transform: translate3d(0,200%,0);
}

figure.effect-zoe p.icon-links a span::before {
    display: inline-block;
    padding: 8px 10px;
    font-family: 'feathericons';
    speak: none;
    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;
}

.icon-eye::before {
    content: '\e000';
}

.icon-paper-clip::before {
    content: '\e001';
}

.icon-heart::before {
    content: '\e024';
}

figure.effect-zoe h2 {
    display: inline-block;
}

figure.effect-zoe:hover p.description {
    opacity: 1;
}

figure.effect-zoe:hover figcaption,
figure.effect-zoe:hover h2,
figure.effect-zoe:hover p.icon-links a {
    //-webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

figure.effect-zoe:hover h2 {
    //-webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

figure.effect-zoe:hover p.icon-links a:nth-child(3) {
    //-webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
}

figure.effect-zoe:hover p.icon-links a:nth-child(2) {
    //-webkit-transition-delay: 0.15s;
    transition-delay: 0.15s;
}

figure.effect-zoe:hover p.icon-links a:first-child {
    //-webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
}
