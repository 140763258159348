.title-bar {
  &.hollow-in-bar{
    justify-content: space-between;
  }
  .title-bar-left,
  .title-bar-right {
    height: 100%;
    background-color: $white;
    padding: .5rem 1rem;
    flex: 0 1 auto;
    @include breakpoint(medium) {
      padding: 1rem 1.5rem;
    }
  }
  .title-bar-right {
    padding: .65rem;
    @include breakpoint(medium) {
      padding: 1.1rem;
    }
  }
}
