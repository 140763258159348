
/* hero section */
.hero {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: top;
  width: 100%;
  overflow: hidden;
  padding-top:3.5rem;
  // margin-top:2.5rem;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(../img/hero-index.jpg);
  &.bg-index {
    height: 100vh;
    // @include breakpoint(medium) {
    //   height: 100vh;
    // }
  }
  img {
    max-width: 35%;
    @include breakpoint(medium) {
      max-width: 20%;
    }
    @include breakpoint(large) {
      max-width: 75%;
    }
  }
  // @include breakpoint(medium) {
  //   height: 65vh;
  // }
  h1 {
    color:white;
    font-size: 1.4rem;
    @include breakpoint(medium) {
      font-size: 1.8rem;
    }
  }
  .menu {
    & > li {
      & > a {
        font-size: 1.3rem;
        font-family: $header-font-family;
        font-weight: 400;
        @include breakpoint(medium) {
          font-size: 1.6rem;
        }
        color: $white;
        &:hover {
          color: darken($white, 15%);
        }
      }
    }
  }
  &.bg-uandani {
    background-position: center center;
    background-image: url(../img/edu/uandani/TalleresWebExe_01.jpg);
  }
}
